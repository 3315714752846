<!-- 双色球基本走势图 -->
<template>
    <div class="">
        <div class="ssq-table">
            <div class="ssq-table-header">
                <div class="ssq-icon">
                    <svg t="1621310782405" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="2538" width="45" height="45">
                        <path
                            d="M541.408711 157.6704h122.3936l44.347733-84.1216-199.529244-1.803378-266.146133 660.383289h63.644444z"
                            fill="#ffffff" p-id="2539"></path>
                        <path
                            d="M585.764978 271.402667h127.689955l44.828445-90.641067h-203.374934l-264.220444 666.843022h61.715911z"
                            fill="#ffffff" p-id="2540"></path>
                        <path
                            d="M813.343289 294.408533h-210.759111l-270.427022 657.846045h209.251555l90.643911-205.960534h126.2336s207.414044 9.910044 234.416356-232.490666c0 0 11.5712-160.924444-179.359289-219.394845z m1.823289 260.667734c-49.169067 80.275911-133.046044 52.315022-133.046045 52.315022l65.558756-159.647289c116.656356 6.764089 67.487289 107.335111 67.487289 107.332267zM203.901156 506.587022c40.4992-92.020622 128.253156-54.129778 128.253155-54.129778l69.415822-158.048711c0-14.819556-323.487289-52.562489-369.316977 239.246223-17.3568 176.822044 177.874489 204.694756 177.874488 204.694755l51.646578-119.893333c-96.079644-59.630933-57.873067-111.869156-57.873066-111.869156z"
                            fill="#ffffff" p-id="2541"></path>
                    </svg>
                    <div class="ssq-one">
                        <h2>中国福利彩票</h2>
                    </div>
                    <div class="ssq-two">
                        <h3>双 色 球 走 势 图</h3>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <table class="tab" border="1" cellspacing="0" cellpadding="0">
                <thead class="thead-fixed">
                    <tr>
                        <th rowspan="2" class="ranks_a">期号</th>
                        <!-- <th rowspan="2" class="ranks_a1">星期</th> -->
                        <th colspan="11" >一区</th>
                        <th colspan="11" >二区</th>
                        <th colspan="11" >三区</th>
                        <th colspan="16" >蓝球</th>
                        <th rowspan="2" class="ranks_a1">和值</th>
                        <th rowspan="2" class="ranks_a1">跨度</th>
                        <th rowspan="2" class="ranks_a1">AC值</th>
                        <th rowspan="2" class="ranks_b">奇偶</th>
                        <th rowspan="2" class="ranks_b">三区比</th>
                    </tr>
                    <tr>
                        <th class="ranks_c" v-for="(item ,i) in red" :key='i'>{{item>9?item: '0'+item}}</th>
                        <th class="ranks_d" v-for="(item ,i) in lan" :key='i'>{{item>9?item: '0'+item}}</th>
                    </tr>
                </thead>
            
                <tbody>
                    <tr v-for="(item ,x) in ssq" :key='x'>
                        <td>{{item.expect}}</td>
                        <!-- <td>{{}}</td> -->
                        <td class="ssq-red ranks_c" v-for="(item1 ,i) in red" :key='i'>
                            <div v-for="(item2 ,a) in item.red" :key='a' :class="item1 == item2 ? 'red' :'no'">
                                {{item1 == item2 ? item2 :''}}
                            </div>
                        </td>
                        <td class="ssq-lan ranks_d" v-for="(item3 ,i) in lan" :key='i'>
                            <div :class="item.lan == item3? 'lan' : 'no'">
                                {{item.lan == item3 ? item3 :''}}
                            </div>
                        </td>
                        <td class="ranks_a1">{{item.he}}</td>
                        <td class="ranks_a1">{{item.red[5]-item.red[0]}}</td>
                        <td class="ranks_a1">{{item.ac}}</td>
                        <td class="ranks_b">{{item.red[0]%2+item.red[1]%2+item.red[2]%2+item.red[3]%2+item.red[4]%2+item.red[5]%2}}:{{6-(item.red[0]%2+item.red[1]%2+item.red[2]%2+item.red[3]%2+item.red[4]%2+item.red[5]%2)}}
                        </td>
                        <td class="ranks_b">{{(item.sqb).toString()}}</td>

                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <!-- <td></td> -->
                        <td v-for="(x , i) in red" :key='i' @click="ball(0,i)">
                            <div :class="xred1.indexOf(i)==-1 ? 'xuan' : 'red' ">
                                {{xred1.indexOf(i)==-1 ? '?' : (i+1>9?i+1: '0'+(i+1)) }}
                            </div>
                        </td>
                        <td v-for="(x , i) in 16" :key='i' @click="ball2(0,i)">
                            <div :class="xlan1.indexOf(i)==-1 ? 'xuan' : 'lan' ">
                                {{xlan1.indexOf(i)==-1 ? '?' : (i+1>9?i+1: '0'+(i+1)) }}
                            </div>
                        </td>
                        <td v-for="(x , i) in 5" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <!-- <td></td> -->
                        <td v-for="(x , i) in red" :key='i' @click="ball(1,i)">
                            <div :class="xred2.indexOf(i)==-1 ? 'xuan' : 'red' ">
                                {{xred2.indexOf(i)==-1 ? '?' : (i+1>9?i+1: '0'+(i+1)) }}
                            </div>
                        </td>
                        <td v-for="(x , i) in 16" :key='i' @click="ball2(1,i)">
                            <div :class="xlan2.indexOf(i)==-1 ? 'xuan' : 'lan' ">
                                {{xlan2.indexOf(i)==-1 ? '?' : (i+1>9?i+1: '0'+(i+1)) }}
                            </div>
                        </td>
                        <td v-for="(x , i) in 5" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <!-- <td></td> -->
                        <td v-for="(x , i) in red" :key='i' @click="ball(2,i)">
                            <div :class="xred3.indexOf(i)==-1 ? 'xuan' : 'red' ">
                                {{xred3.indexOf(i)==-1 ? '?' : (i+1>9?i+1: '0'+(i+1)) }}
                            </div>
                        </td>
                        <td v-for="(x , i) in 16" :key='i' @click="ball2(2,i)">
                            <div :class="xlan3.indexOf(i)==-1 ? 'xuan' : 'lan' ">
                                {{xlan3.indexOf(i)==-1 ? '?' : (i+1>9?i+1: '0'+(i+1)) }}
                            </div>
                        </td>
                        <td v-for="(x , i) in 5" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { mapState, mapMutations, mapActions } from 'vuex'
    import config from '@/utils/config.js'
    // import $ from 'jquery'
    // import '@/js/ssqtable-thead-fixed.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    export default {
        name: 'red-basic',
        components: {

        },
        data() { // 数据源
            return {
                red: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33],
                lan: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                ac: [],
                xred1: [],
                xred2: [],
                xred3: [],
                xlan1: [],
                xlan2: [],
                xlan3: [],
            }
        },
        mounted() {
            this.mssq50()
        },
        computed: {
            ...mapState(['ssq', 'header'])
        },
        methods: { // 函数体部分，js的主要逻辑控制   
            ...mapMutations(['mutationssq']),
            ...mapActions(['actionssq']),

            //号码点击选择函数
            ball(x, i) {
                if (x == 0) {
                    if (this.xred1.indexOf(i) == -1) {
                        this.xred1.push(i);
                        // this.arr0.push(i + 1);
                    } else {
                        this.xred1.splice(this.xred1.indexOf(i), 1);
                        // this.arr0.splice(this.arr0.indexOf(i + 1), 1);
                    }
                } else if (x == 1) {
                    if (this.xred2.indexOf(i) == -1) {
                        this.xred2.push(i);
                        // this.arr0.push(i + 1);
                    } else {
                        this.xred2.splice(this.xred2.indexOf(i), 1);
                        // this.arr0.splice(this.arr0.indexOf(i + 1), 1);
                    }
                } else if (x == 2) {
                    if (this.xred3.indexOf(i) == -1) {
                        this.xred3.push(i);
                        // this.arr0.push(i + 1);
                    } else {
                        this.xred3.splice(this.xred3.indexOf(i), 1);
                        // this.arr0.splice(this.arr0.indexOf(i + 1), 1);
                    }
                }

            },
            //号码点击选择函数
            ball2(x, i) {
                if (x == 0) {
                    if (this.xlan1.indexOf(i) == -1) {
                        this.xlan1.push(i);
                        // this.arr0.push(i + 1);
                    } else {
                        this.xlan1.splice(this.xlan1.indexOf(i), 1);
                        // this.arr0.splice(this.arr0.indexOf(i + 1), 1);
                    }
                } else if (x == 1) {
                    if (this.xlan2.indexOf(i) == -1) {
                        this.xlan2.push(i);
                        // this.arr0.push(i + 1);
                    } else {
                        this.xlan2.splice(this.xlan2.indexOf(i), 1);
                        // this.arr0.splice(this.arr0.indexOf(i + 1), 1);
                    }
                } else if (x == 2) {
                    if (this.xlan3.indexOf(i) == -1) {
                        this.xlan3.push(i);
                        // this.arr0.push(i + 1);
                    } else {
                        this.xlan3.splice(this.xlan3.indexOf(i), 1);
                        // this.arr0.splice(this.arr0.indexOf(i + 1), 1);
                    }
                }

            },

            mssq50() {//走势图
                document.documentElement.scrollTop = 0;
                if (this.ssq == "") {
                    axios({
                        method: "post",
                        url: config.urlcom + "lottery/demo/findj",
                        data: {
                            'tab': "ssq",
                            'zd': "issueno",
                            'i': 50,
                        },
                    }).then(  //this.url是我在上面定义的一个固定的url
                        (res) => {
                            var data = []
                            var x = res.data.reverse()
                            x.forEach((item) => {
                                var red = item.number.split(' ')
                                var lan = item.refernumber
                                // var he = parseInt(red[0]) + parseInt(red[1]) + parseInt(red[2]) + parseInt(red[3]) + parseInt(red[4]) + parseInt(red[5])
                                var he = 0
                                var ac = 0
                                var sqb = []
                                var a2 = 0
                                var a3 = 0
                                var a4 = 0
                                for (var b = 0; b < red.length; b++) {
                                    he += parseInt(red[b])//和值
                                    if (parseInt(red[b]) < 12) {//三区比
                                        a2 += 1
                                    } else if (11 < parseInt(red[b]) && parseInt(red[b]) < 23) {
                                        a3 += 1
                                    } else if (22 < parseInt(red[b])) {
                                        a4 += 1
                                    }
                                }
                                sqb.push(a2, a3, a4)
                                var arr = []
                                for (var j = 0; j < red.length - 1; j++) {
                                    for (var y = j; y < 5; y++) {
                                        arr.push(Math.abs(red[j] - red[y + 1]))
                                    }
                                }
                                var newArr = [];
                                for (var x = 0; x < arr.length; x++) {
                                    if (newArr.indexOf(arr[x]) == -1) {
                                        newArr.push(arr[x]);
                                    }
                                }
                                if ((newArr.length - (6 - 1)) % 10 != 0) {
                                    ac = (newArr.length - (6 - 1)) % 10
                                } else {
                                    ac = 10
                                }
                                var obj = {
                                    "expect": item.issueno,
                                    "red": red,
                                    "lan": lan,
                                    "he": he,
                                    "sqb": sqb,
                                    "ac": ac
                                }
                                data.push(obj)
                            })
                            this.actionssq(data)//vuex函数
                        },
                    )
                    //alert('重新请求数据basic页')
                } else {
                    // alert('vux返回数据basic页')
                    return this.$store.state.ssq;
                }


            },

            mhe() {
                for (var a = 0; a < this.lottery.length; a++) {
                    var a1 = 0
                    var a2 = 0
                    var a3 = 0
                    var a4 = 0
                    for (var b = 0; b < this.lottery[a].red.length; b++) {//和值
                        a1 += parseInt(this.lottery[a].red[b])
                        if (parseInt(this.lottery[a].red[b]) < 12) {//三区比
                            a2 += 1
                        } else if (11 < parseInt(this.lottery[a].red[b]) && parseInt(this.lottery[a].red[b]) < 23) {
                            a3 += 1
                        } else if (22 < parseInt(this.lottery[a].red[b])) {
                            a4 += 1
                        }

                    }
                    this.sqb1.push(a2)
                    this.sqb2.push(a3)
                    this.sqb3.push(a4)
                    this.he.push(a1)
                }
            },

            mac() {//AC值
                for (var i = 0; i < this.lottery.length; i++) {
                    var arr = []
                    for (var j = 0; j < this.lottery[i].red.length - 1; j++) {
                        for (var y = j; y < 5; y++) {
                            arr.push(Math.abs(this.lottery[i].red[j] - this.lottery[i].red[y + 1]))
                        }
                    }
                    var newArr = [];
                    for (var x = 0; x < arr.length; x++) {
                        if (newArr.indexOf(arr[x]) == -1) {
                            newArr.push(arr[x]);
                        }
                    }
                    if ((newArr.length - (6 - 1)) % 10 != 0) {
                        this.ac.push((newArr.length - (6 - 1)) % 10)
                    } else {
                        this.ac.push(10)
                    }

                }

            },
        },

        props: {
        }
    };
 
</script>

<style scoped>
    /* @import './swiper.css'; */
    .ssq-table-header {
        width: 100%;
        height: 50px;
        background-color: red;
    }

    .tab {
        margin: 0 auto;
        border-collapse: collapse;
    }

    .thead-fixed th {
        border: 1px solid #808080;
    }

    .ranks_a {
        width: 60px;
    }
    .rands_b{
        width:30px;
    }
    .ranks_a1 {
        width: 25px;
    }

    .ranks_c {
        width: 23px;
        color: red;
    }

    .ranks_d {
        width: 22px;
        color: blue;
    }

    .ssq-icon {
        text-align: left;
    }

    .icon {
        float: left;
    }

    .ssq-one {
        float: left;
        height: 42px;
        line-height: 42px;
        color: #fff;

    }

    .ssq-two {
        float: left;
        width: 80%;
        text-align: center;
        line-height: 42px;
        font-size: 25px;
        color: rgb(252, 251, 249);
    }

    .ssq-red {
        height: 23px;
        background-color: rgb(224, 191, 191);
    }

    .red {
        background-color: red;
        border-radius: 15px;
        color: #fff;
    }

    tbody .xuan {
        background-color: rgb(160, 156, 156);
        border-radius: 15px;
        color: rgb(253, 253, 253);
    }

    tbody .lan {
        background-color: blue;
        color: #fff;
        border-radius: 15px;
    }

    .ssq-lan {
        background-color: rgb(156, 205, 211);
    }

    .title-guding {
        position: fixed;
        top: -1px;
        background-color: #fff;
        margin-left:-2px;
    }

    .no {
        display: none
    }
</style>